import React from 'react';
import { Locale, MappedInvoiceList } from '../../types';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import 'primeicons/primeicons.css';
import 'primereact/resources/themes/tailwind-light/theme.css';
import 'primeflex/primeflex.css';

const copy: { [key in Locale]: { [key: string]: string } } = {
  'pt-BR': {
    contract: 'Contrato',
    invoice: 'Fatura',
    enterprise: 'Empreendimento',
    unit: 'Unidade',
    resident: 'Morador',
    total: 'Total',
    details: 'Detalhes',
  },
  en: {
    contract: 'Contract',
    invoice: 'Invoice',
    enterprise: 'Enterprise',
    unit: 'Unit',
    resident: 'Resident',
    total: 'Total',
    details: 'Details',
  },
};

//Creation of table columns;
const Table = ({ invoices, locale }: { invoices: MappedInvoiceList; locale: Locale }) => {
  const navigate = useNavigate();

  const columns = [
    { field: 'code', header: copy[locale]['contract'] },
    { field: 'number', header: copy[locale]['invoice'] },
    { field: 'enterpriseName', header: copy[locale]['enterprise'] },
    { field: 'unit', header: copy[locale]['unit'] },
    { field: 'name', header: copy[locale]['resident'] },
    { field: 'total', header: copy[locale]['total'] },
  ];

  const navigateToDetails = (uuid: string) => {
    navigate(`/details/${uuid}?locale=${locale}`);
  };

  //Filter by column;
  const header = <div style={{ textAlign: 'right' }}></div>;

  //Creation of dynamic columns;
  const dynamicColumns = columns.map((col) => {
    return <Column key={col.field} field={col.field} header={col.header} sortable />;
  });

  //Creation of button to details invoices;;
  const buttonDetails = (send: any) => {
    return (
      <Button icon='pi pi-eye' className='p-button-squared p-button-warning' onClick={() => navigateToDetails(send.uuid)} />
    );
  };

  return (
    <div className='card'>
      <DataTable
        lazy
        header={header}
        responsiveLayout='stack'
        className='p-datatable-customers'
        dataKey='id'
        value={invoices}
      >
        {dynamicColumns}
        <Column body={buttonDetails} header={copy[locale]['details']} />
      </DataTable>
    </div>
  );
};

export default Table;
