import { InvoiceStatus, Locale } from '../types';

export const formatDate = (date: string, locale: Locale) => {
  return new Date(date).toLocaleDateString(locale === 'pt-BR' ? 'pt-BR' : 'en-US');
};

export const formatMoney = (value: number, locale: Locale) => {
  return value.toLocaleString(locale === 'pt-BR' ? 'pt-br' : 'en-US', { style: 'currency', currency: 'BRL' });
};

export const formatStatus = (status: InvoiceStatus, locale: Locale) => {
  const map: { [key in Locale]: { [key in InvoiceStatus]: string } } = {
    'pt-BR': {
      OPEN: 'Aberta',
      PAID: 'Paga',
      EXPIRED: 'Vencida',
      REFUND_REQUESTED: 'Estorno Solicitado',
      REFUND_COMPLETED: 'Estornada',
      REFUND_FAILED: 'Falha no Estorno',
      CANCELED: 'Cancelada',
    },
    en: {
      OPEN: 'Open',
      PAID: 'Paid',
      EXPIRED: 'Expired',
      REFUND_REQUESTED: 'Refund Requested',
      REFUND_COMPLETED: 'Refunded',
      REFUND_FAILED: 'Refund Failed',
      CANCELED: 'Canceled',
    },
  };

  return map[locale][status];
};

export const formatCNPJ = (cnpj?: string) => {
  if (!cnpj) return '';

  return (
    cnpj.slice(0, 2) + '.' + cnpj.slice(2, 5) + '.' + cnpj.slice(5, 8) + '/' + cnpj.slice(8, 12) + '-' + cnpj.slice(12, 14)
  );
};

export const formatCEP = (cep?: string) => {
  if (!cep) return '';

  return cep.slice(0, 5) + '-' + cep.slice(5, 8);
};
