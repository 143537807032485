import React from 'react';
import logo from '../../assets/images/logo.png';
import { Locale, Payer } from '../../types';

const copy: { [key in Locale]: { [key: string]: string } } = {
  'pt-BR': {
    welcome: 'Bem-vindo',
  },
  en: {
    welcome: 'Welcome',
  },
};

const Header = ({ payer, locale }: { payer: Omit<Payer, 'invoices'>; locale: Locale }) => {
  return (
    <header className='bg-black pb-8 pt-5 pt-md-8'>
      <nav className='absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4'>
        <div className='w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4'>
          <img className='box-border m-0 p-0 absolute select-none w-28' src={logo} />
          <ul
            id='menu-button'
            aria-expanded='true'
            aria-haspopup='true'
            className='flex-col md:flex-row list-none items-center hidden md:flex'
          >
            <a href='#' className='block shrink-0'>
              <img className='object-cover w-14 h-14 rounded-full' src={logo} alt='User' />
            </a>
          </ul>
        </div>
      </nav>
      <div className='max-w-screen-xl px-4 py-8 mx-auto sm:px-6 lg:px-8'>
        <div className='mt-8'>
          <h1 className='text-center text-2xl font-bold text-white sm:text-3xl'>
            {copy[locale]['welcome']}, {payer.name}
          </h1>
        </div>
      </div>
    </header>
  );
};

export default Header;
