import React from 'react';

interface IListInfos {
  infos: Array<{
    title: string;
    information?: string | number;
  }>;
}

export default function ListInfos({ infos }: IListInfos) {
  return (
    <dl className='ml-2 space-y-1 mt-0.5  text-neutral-400 text-sm'>
      {infos.map((info, index) => {
        return (
          <div key={index}>
            <dt className='inline text-black font-bold mt-2'>{info.title}: </dt>
            {info.information}
          </div>
        );
      })}
    </dl>
  );
}
