import React from 'react';

export default function ButtonStripe({ onClick, title }: { onClick: () => void; title: string }) {
  return (
    <button
      onClick={onClick}
      type='button'
      className='text-black font-bold first-line:text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:ring-gray-100 rounded-lg text-sm px-5 py-4 text-center inline-flex items-center dark:bg-white dark:text-gray-900 dark:hover:bg-gray-200 mr-2 mb-2'
    >
      <svg className='h-6 w-6 text-black' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
        <path
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z'
        />
      </svg>
      <span className='ml-1'>{title}</span>
    </button>
  );
}
