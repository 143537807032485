import axios from 'axios';
import { InvoiceDetails, Payer } from '../../types';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    throw error.response?.data?.message;
  }
);

const getInvoiceList = async (uuid: string) => {
  return (await api.get(`/api/invoices/list/${uuid}`)) as Payer;
};

const getInvoiceDetails = async (uuid: string) => {
  return (await api.get(`/api/invoices/details/${uuid}`)) as InvoiceDetails;
};

// const createPixCharge = async (uuid: string) => {
//   return (await api.post(`/api/invoices/pix/${uuid}`)) as InvoiceDetails;
// };

// const getStripeCustomerPortal = async (contract: number) => {
//   await api.get(`/api/contracts/portal/${contract}`);
// };

export default {
  getInvoiceList,
  getInvoiceDetails,
  // createPixCharge,
  //getStripeCustomerPortal
};
