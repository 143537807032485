import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { InvoiceDetails, Locale } from '../../types';
import logo from '../../assets/images/logo.png';
import ButtonPix from '../../components/Buttons/ButtonPix';

import ListInfos from '../../components/ListInfos';
// import ButtonSubscription from '../../components/Buttons/ButtonSubscription';
import { useCallback } from 'react';
import api from '../../services/api';
import { formatCEP, formatCNPJ, formatDate, formatMoney, formatStatus } from '../../utils/functions';
import ButtonStripe from '../../components/Buttons/ButtonStripe';

const copy: { [key in Locale]: { [key: string]: string } } = {
  'pt-BR': {
    id: 'Identificador da fatura não fornecido',
    fetching: 'Buscando fatura...',
    notFound: 'Fatura não encontrada',
    details: 'Detalhes da fatura',
    status: 'Status',
    resident: 'Morador(a)',
    enterprise: 'Empreendimento',
    unit: 'Unidade',
    total: 'Total',
    period: 'Período de competência',
    dueDate: 'Data de vencimento',
    value: 'Valor',
    rent: 'Aluguel',
    condominium: 'Condomínio',
    tax: 'IPTU',
    expense: 'Reembolso de Despesas',
    fine: 'Multa',
    bankslipFine: 'Multa de Boleto',
    discount: 'Desconto',
    commercialDiscount: 'Desconto Comercial',
    subscription: 'Recorrência',
    creditBoleto: 'Crédito/Boleto',
    credit: 'Crédito',
    boleto: 'Boleto',
    receipt: 'Recibo',
  },
  en: {
    id: 'Invoice ID not provided',
    fetching: 'Fetching invoice...',
    notFound: 'Invoice not found',
    details: 'Details of invoice',
    status: 'Status',
    resident: 'Resident',
    enterprise: 'Enterprise',
    unit: 'Unit',
    total: 'Total',
    period: 'Period',
    dueDate: 'Due date',
    value: 'Value',
    rent: 'Rent',
    condominium: 'Condominium',
    tax: 'Tax',
    expense: 'Expense',
    fine: 'Fine',
    bankslipFine: 'Boleto Fine',
    discount: 'Discount',
    commercialDiscount: 'Commercial Discount',
    subscription: 'Subscription',
    creditBoleto: 'Credit/Boleto',
    credit: 'Credit',
    boleto: 'Boleto',
    receipt: 'Receipt',
  },
};

const Checkout = () => {
  const params = useParams<{ uuid?: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [invoice, setInvoice] = useState<InvoiceDetails>();
  const [locale, setLocale] = useState<Locale>('pt-BR');

  const fetchDetails = useCallback(async () => {
    try {
      if (!params?.uuid) {
        throw new Error(copy[locale]['id']).message;
      }

      const response = await api.getInvoiceDetails(params.uuid);

      setInvoice(response);
    } catch (err) {
      // TODO: mostrar o erro na tela
    }
    setLoading(false);
  }, [params.uuid, locale]);

  const navigateToList = (uuid: any) => {
    navigate(`/${uuid}`);
  };

  const payWithStripe = async (url: string) => {
    window.location.href = url;
  };

  useEffect(() => {
    const localeParam = searchParams.get('locale');
    const value = !localeParam ? 'pt-BR' : localeParam.includes('en') ? 'en' : 'pt-BR';
    setLocale(value);
  }, [searchParams]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  const layout = (content: ReactNode) => {
    return (
      <div className='w-full'>
        <div className='flex bg-white h-screen'>
          <div className=' mr-auto place-self-center lg:col-span-7 flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2'>
            {content}
          </div>
          <div className='sm:w-1/2 xl:w-3/5 h-full hidden md:flex flex-auto items-end justify-end p-10 pr-10 overflow-hidden bg-gray-100 text-white bg-no-repeat bg-cover relative'>
            <img
              src='https://uploads-ssl.webflow.com/609d7b25dbc93b8fa508beb3/61000c92db957294a2a8d53a_jfl-piscina.jpg'
              className='object-cover w-full bg-gray-100 md:h-full rounded '
              loading='lazy'
            />
          </div>
        </div>
      </div>
    );
  };

  if (loading) {
    return layout(<p>{copy[locale]['fetching']}</p>);
  }

  if (!invoice) {
    return layout(<p>{copy[locale]['notFound']}</p>);
  }

  return layout(
    <div>
      <div className='flex mb-6 items-center text-center'>
        <img className='box-border w-28 mx-auto' src={logo} onClick={() => navigateToList(invoice.uuid)} />
      </div>
      <div className='bg-white rounded py-21pl-0.5 pr-0 mr-4'>
        <span className='text-2xl font-semibold text-gray-800 md:text-2xl mr-4 ml-2'>
          {copy[locale]['details']} {invoice.idBus}:
        </span>
        <p className='mt-1 text-black text-1xl font-bold mr-2 ml-2'>
          {copy[locale]['status']}: <span className=' invoice-badge status'> {formatStatus(invoice.status, locale)}</span>
        </p>

        <hr className='my-3 border-gray-200 sm:mx-auto dark:border-gray-700'></hr>

        <div className='flex justify-between mb-4 ml-2 mr-2'>
          <div>
            <p className='text-lg font-semibold text-neutral-700'>
              {copy[locale]['resident']}: {invoice.contract.resident.name}
            </p>
            <p className='mt-0.5 text-black font-bold text-sm'>
              {copy[locale]['enterprise']}:{' '}
              <span className='mt-0.5 text-neutral-400 text-sm'>{invoice.contract.enterpriseName}</span>
            </p>
            <p className='mt-0.5 text-black font-bold text-sm'>
              {copy[locale]['unit']}: <span className='mt-0.5 text-neutral-400 text-sm'>{invoice.contract.unit}</span>
            </p>
            <li className='flex items-center mr-3 mt-3 md:mt-0'></li>
          </div>
          <div className='text-right'>
            <p className='text-lg font-semibold text-neutral-700'>
              {copy[locale]['total']}: {formatMoney(invoice.total, locale)}
            </p>

            <p className='mt-0.5 text-black font-bold text-sm '>
              {copy[locale]['period']}:
              <span className='mt-0.5 text-neutral-400 text-sm'>
                {formatDate(invoice.period.start, locale)} - {formatDate(invoice.period.end, locale)}
              </span>
            </p>
            <p className='mt-0.5 text-black font-bold text-sm'>
              {copy[locale]['dueDate']}:
              <span className=' ml- 0.5 mt-0.5 text-neutral-400 text-sm'>{formatDate(invoice.dueDate, locale)}</span>
            </p>
          </div>
        </div>

        <hr className='my-3 border-gray-200 sm:mx-auto dark:border-gray-700'></hr>

        <ListInfos
          infos={[
            {
              title: invoice.type === 'SPOT' ? copy[locale]['value'] : copy[locale]['rent'],
              information: invoice.values.value > 0 ? formatMoney(invoice.values.value, locale) : undefined,
            },
            {
              title: copy[locale]['condominium'],
              information: invoice.values.condominium > 0 ? formatMoney(invoice.values.condominium, locale) : undefined,
            },
            {
              title: copy[locale]['tax'],
              information: invoice.values.tax > 0 ? formatMoney(invoice.values.tax, locale) : undefined,
            },
            {
              title: copy[locale]['expense'],
              information: invoice.values.expense > 0 ? formatMoney(invoice.values.expense, locale) : undefined,
            },
            {
              title: copy[locale]['fine'],
              information: invoice.values.fine > 0 ? formatMoney(invoice.values.fine, locale) : undefined,
            },
            {
              title: copy[locale]['bankslipFine'],
              information: invoice.values.bankslipFine > 0 ? formatMoney(invoice.values.bankslipFine, locale) : undefined,
            },
            {
              title: copy[locale]['discount'],
              information: invoice.values.discount > 0 ? formatMoney(invoice.values.discount, locale) : undefined,
            },
            {
              title: copy[locale]['commercialDiscount'],
              information:
                invoice.values.commercialDiscount > 0 ? formatMoney(invoice.values.commercialDiscount, locale) : undefined,
            },
          ].filter((info) => info.information)}
        />

        <p className='ml-2 mt-4 text-black text-1xl font-bold'>
          {copy[locale]['total']}: <span> {formatMoney(invoice.total, locale)}</span>
        </p>
      </div>

      {invoice.status === 'OPEN' ? (
        <div className='flex justify-center lg:justify-start mt-3 mb-6'>
          {/* {invoice.possiblePaymentMethods.includes('card') && invoice.type === 'RENT' && invoice.contract.subscription && (
            <ButtonSubscription
              title={copy[locale]['subscription']}
              href={`${process.env.REACT_APP_API_URL}/api/contracts/portal/${invoice.contract.id}`}
            />
          )} */}
          {!invoice.contract.subscription?.active ? (
            invoice.possiblePaymentMethods.includes('boleto') && invoice.possiblePaymentMethods.includes('card') ? (
              <ButtonStripe onClick={() => payWithStripe(invoice.stripePaymentURL)} title={copy[locale]['creditBoleto']} />
            ) : invoice.possiblePaymentMethods.includes('boleto') ? (
              <ButtonStripe onClick={() => payWithStripe(invoice.stripePaymentURL)} title={copy[locale]['boleto']} />
            ) : invoice.possiblePaymentMethods.includes('card') ? (
              <ButtonStripe onClick={() => payWithStripe(invoice.stripePaymentURL)} title={copy[locale]['credit']} />
            ) : null
          ) : null}
          {!invoice.contract.subscription?.active && invoice.possiblePaymentMethods.includes('pix') && (
            <ButtonPix href={`${process.env.REACT_APP_API_URL}/api/invoices/pix/${invoice.uuid}`} />
          )}
        </div>
      ) : invoice.status === 'PAID' && invoice.receipt ? (
        <div className='flex justify-center lg:justify-start mt-3 mb-6'>
          {/* {invoice.possiblePaymentMethods.includes('card') && invoice.type === 'RENT' && invoice.contract.subscription && (
            <ButtonSubscription
              title={copy[locale]['subscription']}
              href={`${process.env.REACT_APP_API_URL}/api/contracts/portal/${invoice.contract.id}`}
            />
          )} */}
          <ButtonStripe onClick={() => payWithStripe(invoice.receipt as string)} title={copy[locale]['receipt']} />
        </div>
      ) : (
        <div className='flex justify-center lg:justify-start mt-3 mb-6'>
          {/* {invoice.possiblePaymentMethods.includes('card') && invoice.type === 'RENT' && invoice.contract.subscription && (
            <ButtonSubscription
              title={copy[locale]['subscription']}
              href={`${process.env.REACT_APP_API_URL}/api/contracts/portal/${invoice.contract.id}`}
            />
          )} */}
        </div>
      )}
      {/* <div className='flex flex-column justify-end text-center'>
        <span>{formatCNPJ(invoice.contract.enterpriseDoc)}</span>
        {invoice.contract.enterpriseAddress && (
          <>
            <span>
              {invoice.contract.enterpriseAddress.address}, {invoice.contract.enterpriseAddress.number} -{' '}
              {invoice.contract.enterpriseAddress.district}
            </span>
            <span>
              {invoice.contract.enterpriseAddress.city} - {invoice.contract.enterpriseAddress.state},{' '}
              {formatCEP(invoice.contract.enterpriseAddress.postalCode)}
            </span>
          </>
        )}
      </div> */}
    </div>
  );
};

export default Checkout;
