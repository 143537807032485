import React from 'react';
import Invoices from '../pages/Invoices';
import { Route, Routes as RoutesDom } from 'react-router-dom';
import Checkout from '../pages/Checkout';

function Routes() {
  return (
    <RoutesDom>
      <Route path='/:uuid' element={<Invoices />} />
      <Route path='/details/:uuid' element={<Checkout />} />
    </RoutesDom>
  );
}

export default Routes;
