import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../components/Header';
import { useParams, useSearchParams } from 'react-router-dom';
import { Locale, MappedInvoiceList, Payer } from '../../types';
import api from '../../services/api';
import { formatMoney } from '../../utils/functions';
import Table from '../../components/Table';

const copy: { [key in Locale]: { [key: string]: string } } = {
  'pt-BR': {
    id: 'Identificador do locatário não fornecido',
    fetching: 'Buscando faturas...',
    notFound: 'Locatário não encontrado',
  },
  en: {
    id: 'Payer ID not provided',
    fetching: 'Fetching invoices...',
    notFound: 'Payer not found',
  },
};

const Invoices = () => {
  const params = useParams<{ uuid?: string }>();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<MappedInvoiceList>();
  const [payer, setPayer] = useState<Omit<Payer, 'invoices'>>();
  const [locale, setLocale] = useState<Locale>('pt-BR');

  const fetchInvoice = useCallback(async () => {
    try {
      if (!params.uuid) {
        throw new Error(copy[locale]['id']).message;
      }

      const response = await api.getInvoiceList(params.uuid);

      setPayer({
        id: response.id,
        uuid: response.uuid,
        name: response.name,
        locale: response.locale,
      });

      setInvoices(
        response.invoices.map((i) => ({
          id: i.id,
          uuid: i.uuid,
          number: i.number,
          code: i.contract.code,
          enterpriseAddress: i.contract.enterpriseAddress,
          enterpriseDoc: i.contract.enterpriseDoc,
          enterpriseName: i.contract.enterpriseName,
          unit: i.contract.unit,
          name: i.contract.resident.name,
          total: formatMoney(i.total, locale),
        }))
      );

      setLoading(false);
    } catch (err) {
      // TODO: mostrar o erro na tela
    }
  }, [params.uuid, locale]);

  useEffect(() => {
    const localeParam = searchParams.get('locale');
    const value = !localeParam ? 'pt-BR' : localeParam.includes('en') ? 'en' : 'pt-BR';
    setLocale(value);
  }, [searchParams]);

  useEffect(() => {
    fetchInvoice();
  }, [fetchInvoice]);

  if (loading) {
    return <p>{copy[locale]['fetching']}</p>;
  }

  if (!payer || !invoices) {
    return <p>{copy[locale]['notFound']}</p>;
  }

  return (
    <>
      <Header payer={payer} locale={locale} />
      <div className='w-full flex items-center justify-center'>
        <div className='py-8 sm:py-12 md:py-8 mb-4 bg-white shadow rounded-lg'>
          <Table invoices={invoices} locale={locale} />
        </div>
      </div>
    </>
  );
};

export default Invoices;
